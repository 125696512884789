import smoothscroll from 'smoothscroll-polyfill';

export default class SmoothScroll {
  constructor() {
    smoothscroll.polyfill();
    const headerHeight = document.getElementById('header').offsetHeight
    const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]')
    // if(window.matchMedia("(min-width:768px)").matches){

    // }
    for (let i = 0; i < smoothScrollTrigger.length; ++i) {
      smoothScrollTrigger[i].addEventListener('click', (e) => {
        e.preventDefault()
        const headBuffer = 0
        const targetPosition = document
          .querySelector(e.currentTarget.getAttribute('href'))
          .getBoundingClientRect().top
        const offsetTop = window.pageYOffset
        const top = targetPosition + offsetTop - headBuffer - headerHeight
        window.scrollTo({
          top,
          behavior: 'smooth',
        })
      })
    }
  }
}
