import { fadeToggle } from "../module/fadeToggle";


/**
 * タブリストコンポーネント
 * @class
 */

export default class TabGroup {
  /**
   * @constructor
   * @param {HTMLElement} element タブリストを表す要素
   */
  constructor(element) {
    /** @member {Tab[]} 内包するタブコンポーネント */
    this.tabs = this.createTabs(element);
  }

  /**
   * リスト内のタブコンポーネントを生成、取得する
   * @param {HTMLElement} list タブリストを表す要素
   * @returns {Tab[]} 内包するタブの一覧
   */
  createTabs(list) {
    const tabElements = [...list.querySelectorAll('span')];

    return tabElements.map(el => new Tab(el, {
      beforeActivate: this.deactivateAll.bind(this),
    }));
  }

  /**
   * リスト内のタブをすべて閉じる
   */
  deactivateAll() {
    this.tabs.forEach(tab => tab.deactivate());
  }

  /**
   * タブリストを初期化する
   * @param {string} selector 初期化対象のセレクター
   */
  static init(selector) {
    document.querySelectorAll(selector)
      .forEach(el => new TabGroup(el));
  }
}

/**
 * タブコンポーネント
 * @class
 */
class Tab {
  /**
   * @constructor
   * @param {HTMLElement} element タブを表す要素
   * @param {object} options 初期化オプション
   * @param {function} options.beforeActivate タブを活性化する直前のコールバック
   */
  constructor(element, { beforeActivate }) {
    /** @member {HTMLElement} タブを表す要素 */
    this.link = element;

    /** @member {HTMLElement} タブパネルを表す要素 */
    this.panel = document.querySelector('[data-panel=' + element.dataset.tab + ']');

    /** @member {function} タブを活性化する直前のコールバック */
    this.beforeActivate = beforeActivate;

    this.handleClick();
  }

  /**
   * クリックイベントを処理する
   */
  handleClick() {
    this.link.addEventListener('click', e => {
      e.preventDefault();

      if (this.isActive()) {
        this.deactivate();
      } else {
        this.activate();
      }
    });
  }

  /**
   * タブが開いているか？
   * @returns {boolean} 判定結果
   */
  isActive() {
    return this.link.classList.contains('is-active')
  }

  /**
   * タブを開く
   */
  activate() {
    if (typeof this.beforeActivate === 'function') {
      this.beforeActivate();
    }

    this.link.classList.add('is-active');
    this.panel.classList.add('is-active');
    // fadeToggle(true,this.panel);
  }

  /**
   * タブを閉じる
   */
  deactivate() {
    this.link.classList.remove('is-active');
    this.panel.classList.remove('is-active');
    // fadeToggle(false,this.panel);
  }
}

// 初期化
