export default class ValidationForm {
  constructor() {
    //.validationForm を指定した最初の form 要素を取得
    const validationForm = document.querySelector(".js-validationForm");
    //required クラスを指定された要素の集まり
    const requiredElems = document.querySelectorAll(".is-required");
    const emailElems = document.querySelectorAll(".email");

    //送信時の処理
    validationForm.addEventListener("submit", (e) => {
      //エラーを表示する要素を全て取得して削除（初期化）
      const errorElems = validationForm.querySelectorAll(".error");
      errorElems.forEach((elem) => {
        elem.remove();
      });

      //.required を指定した全ての要素を forEach() でそれぞれ検証
      requiredElems.forEach((elem) => {
        //チェックボックスの場合
        if (
          elem.tagName === "INPUT" &&
          elem.getAttribute("type") === "radio"
        ) {
          //親要素を基点に選択状態の最初のチェックボックス要素を取得
          const checked = elem
            .closest("dd")
            .querySelector('input[type="radio"]:checked');
          //選択状態のチェックボックス要素を取得できない場合
          if (checked === null) {
            //エラーを表示
            createError(elem, "少なくとも1つを選択してください");
            //フォームの送信を中止
            e.preventDefault();
          }
        } else {
          //値（value プロパティ）の前後の空白文字を削除
          const elemValue = elem.value.trim();
          //値が空の場合はエラーを表示してフォームの送信を中止
          if (elemValue.length === 0) {
            createError(elem, "入力は必須です");
            e.preventDefault();
          }
        }
      });
      //.email を指定した要素を検証
      emailElems.forEach((elem) => {
        //Email の検証に使用する正規表現パターン
        const pattern =
          /^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/iu;
        //値が空でなければ
        if (elem.value !== "") {
          //test() メソッドで値を判定し、マッチしなければエラーを表示してフォームの送信を中止
          if (!pattern.test(elem.value)) {
            createError(elem, "メールアドレスの形式が正しくありません。");
            e.preventDefault();
          }
        }
      });
    });

    //エラーメッセージを表示する span 要素を生成して親要素に追加する関数
    //elem ：対象の要素
    //errorMessage ：表示するエラーメッセージ
    const createError = (elem, errorMessage) => {
      //span 要素を生成
      const errorSpan = document.createElement("span");
      //エラー用のクラスを追加（設定）
      errorSpan.classList.add("error");
      //aria-live 属性を設定
      errorSpan.setAttribute("aria-live", "polite");
      //引数に指定されたエラーメッセージを設定
      errorSpan.textContent = errorMessage;
      //elem の親要素の子要素として追加
      elem.closest("dd").classList.add("is-error");
      elem.parentNode.appendChild(errorSpan);
    };
  }
}
