// import { bodyFixed } from "../module/bodyFixed";
// import { bodyOverlay } from '../module/overlay';
import { fadeToggle } from "../module/fadeToggle";

export default class HamburgerMenu {
  constructor() {
    this.menu = document.getElementById("js-menu");
    this.hamburger = document.getElementById("js-hamburger");
    this.ctaBtn = document.getElementById("js-contactBtn");
  }

  handleClick(triggerId) {
    
    const trigger = document.getElementById(triggerId);
    trigger.addEventListener("click", (e) => {
      e.preventDefault();
      this.menuToggle();
    });
  }

  /**
   * 開閉を切り替える
   */
  menuToggle() {
    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }
  }

  /**
   * 開いているか？
   * @returns {boolean} 判定結果
   */
  isOpen() {
    return this.menu.classList.contains("is-open");
  }

  /**
   * 開く
   */
  open() {
    this.menu.classList.add("is-open");
    this.hamburger.classList.add("is-open");
    this.ctaBtn.classList.add("is-open");
    fadeToggle(true, this.menu);

    // bodyFixed(true);
    // bodyOverlay(true);
  }

  /**
   * 閉じる
   */
  close() {
    this.menu.classList.remove("is-open");
    this.hamburger.classList.remove("is-open");
    this.ctaBtn.classList.remove("is-open");
    fadeToggle(false, this.menu);

    // bodyFixed(false);
    // bodyOverlay(false);
  }
  
}
