import smoothscroll from 'smoothscroll-polyfill';
export default class PageTop {
  constructor() {
    smoothscroll.polyfill();
    const pageTopButton = document.querySelectorAll('.js-pageTop')

    for (let i = 0; i < pageTopButton.length; ++i) {
      pageTopButton[i].addEventListener('click', (e) => {
        e.preventDefault()
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
      })
    }
  }
}
