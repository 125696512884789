/**
 * @description フェードイン
 */
const fadeIn = (el, duration = 300) => {
  el.style.removeProperty("display");
  let display = window.getComputedStyle(el).display;
  if (display === "none") {
    display = "block";
  }
  el.style.display = display;
  el.style.opacity = 0;
  el.style.transition = "opacity " + duration + "ms";

  setTimeout(() => {
    el.style.opacity = 1;
  }, 1);

};

/**
 * @description フェードアウト
 */
const fadeOut = (el, duration = 300) => {
  el.style.opacity = 1;
  el.style.transition = "opacity " + duration + "ms"
  setTimeout(() => {
    el.style.opacity = 0;
  }, 1);
  setTimeout(() => {
    el.style.display = "none"
  }, duration + 10);

};

/**
 * スライド開閉を切り替え
 * @param {boolean} state スライド開閉を切り替える真偽値。true=>開く / false=>閉じる
 */
export const fadeToggle = (state, el, duration = 300) => {
  state ? fadeIn(el, duration) : fadeOut(el, duration);
};

