import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
/**
 * scrollAnimation()
 *
 */
export const scrollAnimation = () => {
  const elements = gsap.utils.toArray(".js-scroll-view");
  elements.forEach((element) => {
    const scrollArgs = {
      start: "top 80%",
      end: "bottom top",
      trigger: element,
      onEnter: () => {
        element.classList.add("is-animated");
      },
    };
    if (element.getAttribute("data-scroll-view") === "fadeSlideIn") {
      gsap.fromTo(
        element,
        {
          x: -20,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: 1,
          scrollTrigger: scrollArgs,
        }
      );
    } else if(element.getAttribute("data-scroll-view") === "parallax") {
      gsap.to(
        element,
        {
          y: 50,
          scrollTrigger: {
            trigger: element,
            start: "top 80%",
            end: "bottom top",
            scrub: 2,
            //markers: true
          }
        }
      );
    } else {
      gsap.to(element, {
        scrollTrigger: scrollArgs,
      });
    }
  });
};
