import HamburgerMenu from "./component/hamburgerMenu";
import SmoothScroll from "./component/smoothScroll";
import PageTop from "./component/pageTop";
import ValidationForm from "./component/validationForm";
import TabGroup from './component/tab'

import { scrollAnimation } from "./module/scrollAnimation";

window.addEventListener("load", () => {

  new HamburgerMenu().handleClick("js-hamburger");

  const top = document.getElementById('toppage')

  if (top !== null) {
    new HamburgerMenu().handleClick("js-link01");
    new HamburgerMenu().handleClick("js-link02");
    new HamburgerMenu().handleClick("js-link03");
    new HamburgerMenu().handleClick("js-link04");
    new HamburgerMenu().handleClick("js-link05");
    new HamburgerMenu().handleClick("js-link06");
    new HamburgerMenu().handleClick("js-link07");
    new HamburgerMenu().handleClick("js-link08");
    new HamburgerMenu().handleClick("js-link09");
  }

  new TabGroup.init('.js-tabs')

  new SmoothScroll();

  new PageTop();
  new ValidationForm();
  scrollAnimation();

  
});
